<template>
  <div style="clear: both" class="modal fade" role="dialog" ref="modalDialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="modal-title text-left">
            {{ $t("data_replacement") }}
            <span class="badge">
              {{ ($refs.stbl && $refs.stbl.nItems) || 0 }}
            </span>
          </div>
        </div>
        <div class="modal-body">
          <section>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group form-group-sm">
                  <label for="">{{ $t("data_list") }}</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="query"
                      v-bind:placeholder="$t('placeholders.search')"
                      ref="query"
                      style="height: 32px"
                    />
                    <div
                      class="input-group-addon btn"
                      @click.stop.prevent="
                        query = '';
                        $refs.query.focus();
                      "
                    >
                      <i
                        :class="query ? 'fa fa-close' : 'fa fa-search'"
                        :style="{ opacity: query ? '0.9' : '0.6' }"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="">{{ $t("destination_connector") }}</label>
                  <v-select
                    v-model="selected"
                    :options="connectorList"
                    :reduce="(option) => option.id"
                    label="name"
                    class="destination"
                  />
                </div>
              </div>
            </div>
            <SearchableTable
              v-if="!busy && items.length"
              :searchEnabled="false"
              :items="items"
              :fields="fields"
              ref="stbl"
            >
              <template #data_from="entry">
                <div class="form-group form-group-sm" style="margin-bottom: 0">
                  <div
                    class="form-control disabled"
                    v-if="entry.item.from"
                    :title="itemTitle(entry.item.from)"
                  >
                    {{ $t(entry.item.from.name) || "-" }}
                  </div>
                </div>
              </template>
              <template #data_to="entry">
                <ControlDataSelector
                  v-if="selected"
                  :connectorSelector="false"
                  :value="
                    (entry &&
                      entry.item &&
                      entry.item.to &&
                      entry.item.to.id) ||
                    ''
                  "
                  :connectorId="selected"
                  :allowedTypes="['bool', 'float', 'int', 'string']"
                  @input="setDataTo(entry.item, $event)"
                  style="margin-bottom: 0"
                />
                <div
                  v-else
                  class="form-group form-group-sm"
                  style="margin-bottom: 0"
                >
                  <div
                    class="form-control disabled"
                    :title="`${$t('invalid_value')}\n${$t(
                      'select_a_connector'
                    )}`"
                  ></div>
                </div>
              </template>
            </SearchableTable>
          </section>
        </div>
        <div class="modal-footer" style="position: relative">
          <div class="pull-left">
            <button
              class="btn btn-default"
              data-dismiss="modal"
              aria-label="Close"
            >
              {{ $t("cancel") }}
            </button>
          </div>

          <div class="pull-right">
            <button
              class="btn btn-primary"
              :disabled="!canSave"
              @click.stop.prevent="save"
            >
              <i class="fa fa-exclamation-triangle"></i>
              {{ $t("confirm") }}
            </button>
          </div>

          <div v-if="canSave" class="text-danger warning" style="">
            <i class="fa fa-exclamation-triangle" style="margin-right: 5px"></i>
            <span v-html="$t('hints.data_replacement')"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchableTable from "@/components/searchable-table.vue";
import ControlDataSelector from "@/components/synoptic/property-editor/controls/control-data-selector";

export default {
  name: "DashboardDataMapper",
  components: {
    SearchableTable,
    ControlDataSelector
  },
  data() {
    return {
      items: [],
      connectorId: null,
      busy: false,
      iQuery: ""
    }
  },
  computed: {
    draft() {
      return this.$store.getters["dashboard/draft"];
    },
    fields() {
      return [
        {
          name: "data_from",
          title: "source",
          style: {
            width: "50%"
          },
          parser(item) {
            return item?.from?.name || '-';
          }
        },
        {
          name: "data_to",
          title: "destination",
          style: {
            width: "50%"
          },
          parser(item) {
            return item?.to?.name || '-';
          }
        }
      ]
    },
    canSave() {
      return this.dataIdMap;
    },
    dataList() {
      return this.$store.getters["dashboard/extendedDataList"];
    },
    connectorList() {
      return this.$store.getters["dashboard/connectorList"];
    },
    screenConnector() {
      var screen = this.$store.getters["dashboard/screens"].find(({ id }) => parseInt(this.draft.screenId) == parseInt(id));
      var refId = ((screen?.reference_connectors || [])[0] || {})?.id || this.connectorId;
      return refId ? (this.connectorList || []).find(({ id }) => parseInt(refId) == parseInt(id)) : null;
    },
    selected: {
      set(value) {
        if (parseInt(this.selected) != parseInt(value)) {
          this.items.forEach((item) => (item.to = null));
          this.connectorId = value;
          this.busy = true;
          this.$nextTick(() => {
            this.busy = false;
          });
        }
      },
      get() {
        return this.connectorId;
      }
    },
    dataIdMap() {
      let map = {};
      this.items.forEach((item) => item.to && item.to.id != item.from.id && (map[item.from.id] = item.to.id));
      return Object.keys(map).length && map || null;
    },
    query: {
      set(value) {
        this.iQuery = value;
        if (this?.$refs?.stbl) this.$refs.stbl.query = value;
      },
      get() {
        return this.iQuery;
      }
    }
  },
  methods: {
    close() {
      $(this.$refs.modalDialog).modal("hide");
    },
    open() {
      $(this.$refs.modalDialog).modal("show");
    },
    setDataTo(item, dataId) {
      this.$set(
        item,
        "to",
        dataId
          ? (this.dataList || []).find(({ id }) => (id) == (dataId))
          : null
      );
    },
    build() {
      const _getDataIds = (template, recursive = false) => {
        let dataIds = {};
        for (let prop in template) {
          if (typeof template[prop] == "object") {
            dataIds = {
              ...dataIds,
              ..._getDataIds(template[prop], true)
            };
          } else if (prop == "data_id" && template[prop]) {
            dataIds[template[prop]] = true;
          }
        }
        if (!recursive) dataIds = Object.keys(dataIds);
        return dataIds;
      };

      this.$set(
        this,
        "items",
        _getDataIds(this.draft.template).map((dataId) => ({
          from: this.dataList.find((({ id }) => id == dataId)),
          to: null
        }))
      );
      this.connectorId = this?.draft?.template?.draft?.refMap?.conn1 && (this?.draft?.template?.draft?.refMap?.conn1) || '';
    },
    save() {
      if (!this.canSave) return;
      let map = this.dataIdMap;
      const _replace = (template) => {
        for (let prop in template) {
          if (typeof template[prop] == "object") {
            _replace(template[prop], true);
          } else if (
            prop == "data_id" &&
            template[prop] &&
            map[template[prop]]
          ) {
            template[prop] = map[template[prop]];
          }
        }
      };
      let template = JSON.parse(JSON.stringify(this.draft.template));
      _replace(template);
      this.$store.dispatch("dashboard/saveDraft", {
        screenId: this.draft.screenId,
        template: template
      });
      this.close();
    },
    itemTitle(item) {
      let lst = [];
      if (item.device.connector) {
        let device = item.device || null;
        let connector = device.connector || null;
        if (isNaN(parseInt(item.id)) && connector) {
          lst.push(`${item.name}`);
          connector = this.connectorList.find(({ id }) => parseInt(id) == parseInt(connector.id));
          device = null;
        }
        else {
          lst.push(`#${item.id} ${item.name}`);
        }
        connector && lst.push(`${this.$tc('connector', 1)}: ${connector?.id ?? ''} ${connector?.name ?? ''}`);
        device && lst.push(`${this.$tc('device', 1)}: ${device?.id ?? ''} ${device?.name ?? ''}`);
      }
      item.identity_embedded_app && lst.push(`${this.$t('titles.identity_embedded_app')}: ${item.identity_embedded_app}`);
      return lst.join("\n");
    }
  },
  mounted() {
    let self = this;
    $(this.$refs.modalDialog)
      .on("shown.bs.modal", () => {
        self.$root.$emit("editor.keyboard:stop");
        self.$emit("open");
      })
      .on("hidden.bs.modal", () => {
        self.$root.$emit("editor.keyboard:start");
        self.$nextTick(() => {
          self.$emit("close");
        })
      });
    this.open();
  },
  created() {
    this.build();
  }
}
</script>

<style  scoped>
.modal-content {
  border-radius: 5px;
}
.modal-title {
  font-size: 14pt;
  line-height: initial;
}
.modal-body {
  min-height: 50dvh;
  max-height: 80dvh;
  overflow: auto;
}

.modal-body > section {
}
.badge {
  margin: -15px 0 0 -1px;
  background-color: #777;
}

div.form-control.disabled {
  background-color: #eee;
}

.searchable-table::v-deep > section {
  margin: 20px 0 -20px 0;
}

.warning {
  text-align: center;
  position: absolute;
  width: 60%;
  left: 20%;
  margin-top: 5px;
  line-height: 1em;
  font-size: 90%;
}

.destination::v-deep > div > div > .vs__search {
  min-height: 22px;
}
</style>
