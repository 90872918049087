<template>
  <div class="me">
    <div class="text-center add-button" v-if="collapsed">
      <!-- @click.stop.prevent="add" -->
      <span
        class="btn btn-xs btn-primary"
        v-if="items.length"
        :disabled="!items.length"
        @click.stop.prevent="collapsed = items.length ? false : true"
      >
        {{ $t("synoptic.events.add_an_event") }}
      </span>
    </div>
    <div class="form-group form-group-sm" v-else>
      <div class="form-group-label">
        {{ $t("synoptic.events.select_an_event") }}
        <Tooltip
          :title="$t('hints.select_the_event_that_will_trigger_actions')"
        />
        <span
          title="Fechar"
          class="pull-right clicable"
          @click.stop.prevent="collapsed = true"
        >
          <i class="fa fa-close"></i>
        </span>
      </div>
      <div class="input-group">
        <div class="input-group-addon">
          <small> {{ $tc("new") }} {{ $tc("event", 1) }} </small>
        </div>
        <select
          v-model="selected"
          class="form-control select-sm"
          id="event_selection"
          style="font-size: 90%"
        >
          <option value="">{{ $t("synoptic.events.select_an_event") }}</option>
          <option :value="item" v-for="(item, ix) in items" :key="ix">
            {{ $tc(`synoptic.events.${item}`) }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import ControlsSettings from "@/assets/dashboard/controls_settings.json";
import Tooltip from "@/components/tooltip.vue";
export default {
  name: "ControlEventSelector",
  components: {
    Tooltip
  },
  props: {
    control: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selected: "",
      collapsed: true
    };
  },
  computed: {
    eventList() {
      if (!this.control) return [];
      let componentName = this?.control?.synopticComponent?.componentName || this?.control?.componentName || "Button";
      return componentName ? ControlsSettings[componentName].events : ["click"];
    },
    items() {
      // it might be a regular panel control (not a synoptic one)
      let lst = JSON.parse(JSON.stringify(this.eventList || []));
      let inserted =
        this?.control?.synopticComponent?.on || this?.control?.on || {};
      for (let name in inserted) {
        let ix = lst.findIndex((item) => item == name);
        if (ix >= 0) {
          lst.splice(ix, 1);
        }
      }
      return lst;
    }
  },
  watch: {
    selected(n) {
      if (n) {
        this.$emit("addEvent", this.selected);
        this.collapsed = true;
      }
    },
    items: {
      handler(n) {
        this.selected = "";
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    add() {
      this.$emit("addEvent", this.selected);
    }
  }
};
</script>

<style scoped>
.me {
  margin: 0;
  padding: 0 10px;
  /* border-bottom: 1px solid lightgray; */
}
.add-button {
  padding: 30px 0 60px 0;
}

.form-group-sm {
  margin-bottom: 0;
}

.select-sm {
  font-size: 12pt;
  padding: 0;
}

.btn-primary {
  color: #fff;
  background-color: #204d74;
  border-color: #122b40;
}
</style>
