<template>
  <div class="js-script-editor-sidebar">
    <div>
      <div class="form-group form-group-sm no-margin">
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            v-model="query"
            ref="query"
            @keypress.enter="addItem"
            placeholder="new_function"
          />
          <div class="input-group-addon btn" @click.stop.prevent="addItem">
            <i :class="canAdd ? 'fa fa-plus clicable' : 'fa fa-search'"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="no-select">
      <ul class="list-group">
        <li
          class="list-group-item clicable"
          v-for="item in filteredList"
          :key="item.id"
          :class="item.id == active ? 'active' : ''"
          :title="item.id"
          @click.stop.prevent="activeItem = item.id"
        >
          <div class="item-label">
            <span class="fn-prefix">$</span>{{ item.text }}
          </div>
          <div class="item-toolbar">
            <i
              class="fa fa-copy"
              @click.stop.prevent="copyItem(item.id)"
              :title="$t('copy', { item: item.id })"
            ></i>
            <i
              class="fa fa-trash"
              @click.stop.prevent="removeItem(item.id)"
              :title="$t('remove')"
            ></i>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "JSScriptSideBar",
  props: {
    code: {
      type: Object,
      required: false,
      default: () => { }
    },
    active: {
      type: String,
      required: false,
      default: ""
    }
  },
  data: () => ({
    query: ""
  }),
  computed: {
    list() {
      return Object.keys(this.code || {})
        .sort()
        .map((i) => ({
          id: i,
          text: i.replace(/^[$]/, ""),
          type: "const"
        }));
    },
    filteredList() {
      return this.query
        ? this.list.filter(({ text }) => {
          return text.toUpperCase().startsWith(this.query.toUpperCase());
        })
        : this.list;
    },
    activeItem: {
      set(value) {
        this.$emit("select", value);
      },
      get() {
        return this.active;
      }
    },
    canAdd() {
      return this.query && this.filteredList.length == 0;
    }
  },
  methods: {
    addItem() {
      if (!this.canAdd) return;
      let id = `$${this.query}`;
      this.query = "";
      this.$emit("addItem", id);
      this.$nextTick(() => {
        this.activeItem = id;
      });
    },
    removeItem(id) {
      this.$utils
        .confirm(this, "you_wont_be_able_to_revert_this")
        .then((resp) => {
          if (!resp) return;
          this.$emit("removeItem", id);
          this.$refs.query.focus();
        });
    },
    copyItem(id) {
      this.$utils.toClipboard(id || "");
    }
  }
};
</script>

<style scoped>
div.js-script-editor-sidebar {
}
.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}

.list-group-item {
  position: relative;
  padding: 6px 6px;
  white-space: nowrap;
}

.list-group-item:not(.active):hover {
  color: rgb(24, 76, 110);
  background-color: whitesmoke;
}

.list-group-item > .item-label {
  min-width: 150px;
  font-weight: 400;
}
.list-group-item > .item-label > .fn-prefix {
  color: rgb(148, 15, 160);
  font-size: 90%;
  margin: 0 1px 0 -4px;
}

.list-group-item > .item-toolbar {
  position: absolute;
  color: #888;
  right: 0;
  top: 0;
  bottom: 0;
}

.list-group-item > .item-toolbar > i {
  padding: 8px 6px 6px 6px;
  background-color: white;
}

.list-group-item > .item-toolbar > i:hover {
  color: orangered;
}

.list-group-item.active > .item-toolbar > i {
  background-color: transparent;
  color: white;
}

.no-margin {
  margin: 0;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
</style>
