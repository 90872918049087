<template>
  <div :class="['alert', { [`alert-${type}`]: type }]" role="alert">
    <slot>
      Alert
    </slot>
  </div>
</template>

<script>
export default {
  name: "Alert",
  props: {
    type: {
      type: String,
      required: false,
      default: ""
    }
  }
};
</script>

<style></style>
