<template>
  <div v-if="linkedScreen" class="linked-screen">
    <div class="form-group">
      <label for="">{{ $t("titles.remote_panel") }}</label>
      <div>
        {{ $t("titles.source_screen") }}
      </div>
      <div
        class="input-group"
        @click.stop.prevent="openDashboard(linkedScreen.screenId)"
      >
        <div
          class="input-group-addon btn"
          :title="`${$t('edit')} ${linkedScreen.name}`"
        >
          <i class="fa fa-desktop"></i>
        </div>
        <div class="form-control" :title="`${$t('edit')} ${linkedScreen.name}`">
          {{ linkedScreen.name }}
        </div>
        <i class="fa fa-pencil" />
      </div>
    </div>
    <div class="form-group">
      <label @click.stop.prevent="togglePanelSync" class="clicable">
        <i
          :class="
            isPanelSyncEnabled ? 'fa fa-check-square-o' : 'fa fa-square-o'
          "
          style="min-width: 16px; vertical-align: middle"
        ></i>
        <span>
          {{ $t("titles.sync_enabled") }}
        </span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "LinkedPanelForm",
  props: {
    template: {
      type: Object,
      required: true
    },
    panelName: {
      type: String,
      required: true
    }
  },
  computed: {
    linkedScreen() {
      let entry = (this?.template?.linkedPanels || {})[this.panelName];
      return (
        (entry && {
          ...entry,
          ...this.$store.getters["dashboard/screens"].find(
            ({ id }) => entry.screenId == id
          )
        }) ||
        null
      );
    },
    isPanelSyncEnabled() {
      return this.linkedScreen?.syncEnabled || false;
    }
  },
  methods: {
    updateSync(option) {
      this.$root.$emit("dashboard:editor", {
        action: "setPanelSync",
        data: {
          panelName: this.panelName,
          option: option
        }
      });
    },
    togglePanelSync() {
      let option = !this.isPanelSyncEnabled;
      if (option) {
        let template = this.$store.getters["dashboard/template"](this.linkedScreen.screenId);
        if (template) {
          let panel = template.panels.find(({ name }) => name == this.panelName);
          if (panel) {
            this.updateSync(option);
          }
        }
        else {
          this.$store.dispatch("dashboard/fetchTemplate", this.linkedScreen.screenId).then((template) => {
            let panel = template.panels.find(({ name }) => name == this.panelName);
            if (panel) {
              this.updateSync(option);
            }
          });
        }
      }
      else {
        this.updateSync(option);
      }
    },
    openDashboard(screenId) {
      if (screenId) {
        this.$root.$emit("dashboard:editor", {
          action: "openDashboard",
          data: { screenId: screenId }
        });
      }
    }
  }
};
</script>

<style scoped>
.clicable {
  opacity: 0.8;
  cursor: pointer;
}
.linked-screen {
  width: 100%;
}

.linked-screen > .form-group {
  margin-bottom: 10px;
}
.linked-screen > .form-group > label {
  /* margin-bottom: 10px; */
}
.linked-screen > .form-group .form-control {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.linked-screen > .form-group > .input-group {
  position: relative;
  background-color: transparent;
}

.linked-screen > .form-group > .input-group > i {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 3;
}

.linked-screen > .form-group:hover .form-control,
.linked-screen > .form-group > .input-group,
.linked-screen > .form-group:hover i {
  color: #476885;
  cursor: pointer;
}
</style>
